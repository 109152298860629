import { Global } from '@emotion/react';
import 'antd/dist/antd.css';
import { NextComponentType } from 'next';
import { AppContext, AppInitialProps, AppLayoutProps } from 'next/app';
import Head from 'next/head';
import { ReactNode } from 'react';
import xw from 'xwind';
import MainLayout from '../components/MainLayout';
import '../override.css';

import { QueryClient, QueryClientProvider } from 'react-query';
import { useRouter } from 'next/router';

const GOOGLE_MAP_API_URL = `//maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_API_KEY}&language=en&libraries=places`;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false
    }
  }
});

const App: NextComponentType<AppContext, AppInitialProps, AppLayoutProps> = ({
  Component,
  pageProps
}: AppLayoutProps) => {
  const getLayout = Component.getLayout || ((page: ReactNode) => <MainLayout>{page}</MainLayout>);

  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <title>QHI Site</title>
        <script async defer src={GOOGLE_MAP_API_URL} />
      </Head>
      <Global
        //tailwind base styles + keyframes + ring and shadow classes variables  ... to global styles
        styles={xw`XWIND_BASE XWIND_GLOBAL`}
      />
      {getLayout(<Component {...pageProps} />)}
    </QueryClientProvider>
  );
};

export default App;
