import axios from 'axios';
import { InspectionOrder } from '../entities';
import {
  AddContractorPayload,
  AddJobToServiceOrderPayload,
  ArchivePayload,
  ConfirmRepeatOrderPayload,
  CreateCommentPayload,
  CreateInspectionOrderPayload,
  CreateServiceOrderPayload,
  CreateTagPayload,
  CreateUserPayload,
  SaveClientPayload,
  SaveClientResponse,
  SaveContactDetailPayload,
  SchedulesPayload,
  ServiceJobMetaPayload,
  ServiceOrderIdPayload,
  SigningDocumentsPayload,
  UpdateAdditionalQuotesPayload,
  UpdateAddressMemoPayload,
  UpdateCommentPayload,
  UpdateContactPendingPayload,
  UpdateContractorPaymentsPayload,
  UpdateInspectionOrderPayload,
  UpdateServiceJobPayload,
  UpdateServiceOrderPayload,
  UpdateUserPayload,
  addressDetails
} from '../types';
import { ServiceOrder, Tag } from './../entities';
import { AddJobPayload, ConfirmDraftPayload, JobSchedulesPayload, UpdateJobPayload } from './../types';

export const fetchAddressPredictions = async (text: string) =>
  new Promise((resolve, reject) => {
    if (!text) {
      return reject('Search query cannot be empty.');
    }

    new window.google.maps.places.AutocompleteService().getPlacePredictions(
      { input: text, componentRestrictions: { country: 'nz' } },
      resolve
    );
  });

export const fetchPlaceDetails = async (placeId: string) => {
  if (!placeId) {
    throw 'placeId: cannot be empty.';
  }

  const { data } = await axios.get(`/api/placedetails`, {
    params: { place_id: placeId, key: process.env.GOOGLE_API_KEY }
  });

  if (!data) {
    throw 'Cannot get anything from google place details API.';
  }

  const { result } = data;
  const { address_components, geometry } = result || {};
  const placeDetails: addressDetails = {};

  if (address_components?.length) {
    address_components.forEach((component: { types: string[]; long_name: string }) => {
      const { types, long_name } = component;

      if (types.find((type: string) => ['locality'].indexOf(type) !== -1)) {
        placeDetails.city = long_name;
      }
      if (types.find((type: string) => ['sublocality_level_1', 'sublocality'].indexOf(type) !== -1)) {
        placeDetails.suburb = long_name;
      }
      if (types.indexOf('postal_code') !== -1) {
        placeDetails.postcode = long_name;
      }
    });
  }

  if (geometry) {
    const { lat, lng } = geometry?.location || {};
    if (lat && lng) {
      placeDetails.location = [lat, lng];
    }
  }
  return placeDetails;
};

export const searchClient = (searchText: string) =>
  axios.get(`/api/customer/search?value=${searchText}`).then((res) => res.data);

export const getContactData = (code: string) => axios.get(`/api/contact/${code}`).then((res) => res.data);

export const getOrderData = (code: string) =>
  axios.get<InspectionOrder>(`/api/orders/${code}`).then((res) => ({
    ...res.data,
    jobs: res.data.jobs.map((job) => ({
      ...job,
      costInCents: (job.costInCents ?? 0) / 100
    }))
  }));

export const setOrderAnnualNotifyStates = (orderId: string, jobType: string, status: boolean) =>
  axios.post('/api/order/annual-notify', { orderId, jobType, status }).then((res) => res.data);

export const getUserProfile = () => axios.get(`/api/user/profile`).then((res) => res.data);

export const updateUser = (payload: UpdateUserPayload) =>
  axios.post('/api/user/update', payload).then((res) => res.data);

export const resetUser = (payload: UpdateUserPayload) => axios.put('/api/user/update', payload).then((res) => res.data);

export const createUser = (user: CreateUserPayload) => axios.post('/api/user/create', user).then((res) => res.data);

export const getUsers = () => axios.get('/api/user/list').then((res) => res.data);

export const getInspectors = () => axios.get('/api/user/inspectors').then((res) => res.data);

export const getComments = (params: { title?: string; category?: string; tags?: string; page?: number }) =>
  axios.get('/api/comments/list', { params }).then((res) => res.data ?? {});

export const addComment = (payload: CreateCommentPayload) =>
  axios.post('/api/comments/create', payload).then((res) => res.data);

export const updateComment = (payload: UpdateCommentPayload) =>
  axios.post('/api/comments/update', payload).then((res) => res.data);

export const deleteComment = (payload: UpdateCommentPayload) =>
  axios
    .delete('api/comments/delete', {
      data: payload
    })
    .then((res) => res.data);

export const addTags = (payload: CreateTagPayload) => axios.post('/api/comments/tags', payload).then((res) => res.data);

export const getTags = (category: string) =>
  axios.get<Tag[]>('/api/comments/tags', { params: { category } }).then((res) => res.data);

export const getSchedules = ({ start, end }: SchedulesPayload) =>
  axios.get('/api/job/schedules', { params: { start, end } }).then((res) => res.data);

export const postAgreementSignature = (payload: SigningDocumentsPayload) =>
  axios.post('/api/order/sign', payload).then((res) => res.data);

export const confirmRepeatOrder = (payload: ConfirmRepeatOrderPayload) =>
  axios.post('/api/order/confirm-repeat', payload).then((res) => res.data);

export const postServiceOrderAgreementSignature = (payload: SigningDocumentsPayload) =>
  axios.post('/api/service-order/sign', payload).then((res) => res.data);

export const postAddJob = (payload: AddJobPayload) => axios.post(`/api/job/add`, payload).then((res) => res.data);

export const postAddServiceOrderJob = (payload: AddJobToServiceOrderPayload) =>
  axios.post(`/api/service-order/add-job`, payload).then((res) => res.data);

export const postUpdateServiceOrderJob = (payload: UpdateServiceJobPayload) =>
  axios.post(`/api/service-order/update-job`, payload).then((res) => res.data);

export const markServiceOrderJobAccepted = (payload: ServiceJobMetaPayload) =>
  axios.post(`/api/service-order/accept-job`, payload).then((res) => res.data);

export const markServiceOrderJobCompleted = (payload: ServiceJobMetaPayload) =>
  axios.post(`/api/service-order/complete-job`, payload).then((res) => res.data);

export const markServiceOrderJobCancelled = (payload: ServiceJobMetaPayload) =>
  axios.post(`/api/service-order/cancel-job`, payload).then((res) => res.data);

export const postScheduleJob = (payload: JobSchedulesPayload) =>
  axios.post(`/api/job/schedules`, payload).then((res) => res.data);

export const postUpdateJob = (payload: UpdateJobPayload) =>
  axios.post(`/api/job/update`, payload).then((res) => res.data);

export const postConfirmDraft = (payload: ConfirmDraftPayload) =>
  axios.post(`/api/order/confirm-draft`, payload).then((res) => res.data);

export const postMarkAsPaid = (orderId: string) => axios.post(`/api/order/paid`, { orderId }).then((res) => res.data);

export const postMarkAsArchived = (payload: ArchivePayload) =>
  axios.post(`/api/order/archive`, payload).then((res) => res.data);

export const markServiceOrderArchived = (payload: ArchivePayload) =>
  axios.post(`/api/service-order/archive`, payload).then((res) => res.data);

export const postUpdateOrder = (
  payload:
    | UpdateAdditionalQuotesPayload
    | UpdateContactPendingPayload
    | UpdateInspectionOrderPayload
    | UpdateAddressMemoPayload
) => axios.post('/api/order/update', payload).then((res) => res.data);

export const postUpdateServiceOrder = (payload: UpdateServiceOrderPayload | UpdateAddressMemoPayload) =>
  axios.post('/api/service-order/update', payload).then((res) => res.data);

export const postContactData = (code: string, payload: SaveContactDetailPayload) =>
  axios.post(`/api/contact/${code}`, payload).then((res) => res.data);

export const postCreateOrder = (payload: CreateInspectionOrderPayload) =>
  axios.post('/api/order/create', payload).then((res) => res.data);

export const postCreateServiceOrder = (payload: CreateServiceOrderPayload) =>
  axios.post('/api/service-order/create', payload).then((res) => res.data);

export const cloneMTLDOrder = (payload: { jobId: string }) =>
  axios.post('/api/job/clone', payload).then((res) => res.data);

export const upsertClient = (payload: SaveClientPayload) =>
  axios.post<SaveClientResponse>('/api/customer/save', payload).then(({ data }) => data);

export const sendReportManually = (payload: { orderNumber: string }) =>
  axios.post('/api/order/send-reports', payload).then(({ data }) => data);

export const sendWorkFilesManually = (payload: { orderNumber: string }) =>
  axios.post('/api/service-order/send-files', payload).then(({ data }) => data);

export const logout = () => axios.get('/api/logout');

export const postCloneOrder = (payload: any) => axios.post(`/api/order/clone`, payload).then((res) => res.data);

export const postAddContractor = (payload: AddContractorPayload) =>
  axios.post('/api/contractor/save', payload).then((res) => res.data);

export const postUpdateContractor = (payload: AddContractorPayload) =>
  axios.post('/api/contractor/save', payload).then((res) => res.data);

// TODO: Implement the data api for rendering the pdf.
export const getPDFPreview = (payload: any) => axios.post('/api/pdf/preview', payload).then((res) => res.data);

export const confirmServiceOrder = (orderId: string, skipAgreement: boolean) =>
  axios.post('/api/service-order/confirm-order', { orderId, skipAgreement }).then((res) => res.data);

export const issueServiceQuotations = (payload: ServiceJobMetaPayload) =>
  axios.post('/api/service-order/issue-quotations', payload).then((res) => res.data);

export const issueServiceWorkOrders = (payload: ServiceOrderIdPayload) =>
  axios.post('/api/service-order/issue-work-orders', payload).then((res) => res.data);

export const signServiceAgreement = (payload: SigningDocumentsPayload) =>
  axios.post('/api/service-order/sign-agreement', payload).then((res) => res.data);

export const getServiceOrderById = (orderId: string, baseUrl?: string) => {
  const url = baseUrl ? `${baseUrl}/api/service-order/${orderId}` : `/api/service-order/${orderId}`;
  return axios.get<ServiceOrder>(url).then(
    ({ data }) =>
      ({
        ...data,
        jobs: (data?.jobs || []).map((job) => ({
          ...job,
          contractorQuoteInCents: job.contractorQuoteInCents / 100 || 0,
          additionalCostInCents: (job.additionalCostInCents ?? 0) / 100,
          totalCostInCents: job.totalCostInCents / 100 || 0,
          workPriceInCents: job.workPriceInCents / 100 || 0
        })),
        prepayment: {
          ...data.prepayment,
          amountInCent: (data.prepayment?.amountInCent ?? 0) / 100
        },
        finalPayment: {
          ...data.finalPayment,
          amountInCent: (data.finalPayment?.amountInCent ?? 0) / 100
        }
      } as ServiceOrder)
  );
};

export const markContractorsAsPaid = (payload: UpdateContractorPaymentsPayload) => {
  return axios.post('/api/service-order/pay-contractor', payload).then((res) => res.data);
};
