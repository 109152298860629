import xw from 'xwind';
import { FC, ReactNode } from 'react';
import { Layout } from 'antd';
import NavBar from './NavBar';
import { Content } from 'antd/lib/layout/layout';
import { useUserProfile } from '../hooks/useUserProfile';
import { LoadingOutlined } from '@ant-design/icons';
import { useRouter } from 'next/dist/client/router';
import PageLoadingIndicator from './PageLoadingIndicator';

type Props = {
  children?: ReactNode;
};

type LayoutProps = {
  page?: ReactNode;
};

const MainLayout: FC<Props> = ({ children }) => {
  const { isLoading, isError, isSuccess } = useUserProfile();
  const router = useRouter();
  if (isLoading) {
    return (
      <Layout css={xw`h-screen flex justify-center items-center`}>
        <LoadingOutlined style={{ fontSize: 96, maxWidth: '150px' }} spin />
        <div css={xw`mt-8`}>Loading...</div>
      </Layout>
    );
  }

  if (isError) {
    router.push('/login');
  }

  if (isSuccess) {
    return (
      <Layout hasSider>
        <NavBar />
        <Layout css={xw`relative min-h-screen`} style={{ marginLeft: 200 }}>
          <PageLoadingIndicator>
            <Content css={xw`m-4`}>{children}</Content>
          </PageLoadingIndicator>
        </Layout>
      </Layout>
    );
  }
  return null;
};

export const getLayout = ({ page }: LayoutProps) => <MainLayout>{page}</MainLayout>;

export default MainLayout;
