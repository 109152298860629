import {
  ApiOutlined,
  CalendarOutlined,
  ContactsOutlined,
  ContainerOutlined,
  ControlOutlined,
  DashboardOutlined,
  FileAddOutlined,
  KeyOutlined,
  LogoutOutlined,
  MessageOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useRouter } from 'next/dist/client/router';
import { FC } from 'react';
import xw from 'xwind';
import { useCurrentUserRole } from '../hooks/useUserProfile';
import { logout } from '../services/api-service';

const { Sider } = Layout;

const NavBar: FC = () => {
  const router = useRouter();
  const { isSuperAdmin, isAdmin } = useCurrentUserRole();

  const menuItems = [
    {
      label: 'Dashboard',
      key: '/',
      icon: <DashboardOutlined />,
      onClick: () => router.push('/')
    },
    {
      label: 'Orders',
      key: '/order',
      icon: <ContainerOutlined />,
      onClick: () => router.push('/order')
    },
    {
      label: 'New Order',
      key: '/order/create',
      icon: <FileAddOutlined />,
      onClick: () => router.push('/order/create')
    },
    {
      label: 'Building Work Orders',
      key: '/service-order',
      icon: <ContainerOutlined />,
      onClick: () => router.push('/service-order')
    },
    {
      label: 'New Building Works Order',
      key: '/service-order/create',
      icon: <FileAddOutlined />,
      onClick: () => router.push('/service-order/create')
    },
    {
      label: 'KPIs',
      key: '/kpis',
      icon: <FileAddOutlined />,
      onClick: () => router.push('/kpis')
    },
    {
      label: 'Comments',
      key: '/comments',
      icon: <MessageOutlined />,
      onClick: () => router.push('/comments')
    },
    {
      label: 'Contractors',
      key: '/contractors',
      icon: <TeamOutlined />,
      onClick: () => router.push('/contractors')
    },
    ...(isAdmin && !isSuperAdmin ? [
      {
        label: 'App Config',
        key: '/inspection/config',
        icon: <ControlOutlined />,
        onClick: () => router.push('/inspection/config')
      }
    ] : []),
    ...(isSuperAdmin
      ? [
          {
            label: 'Access',
            key: '/access',
            icon: <KeyOutlined />,
            onClick: () => router.push('/access')
          },
          {
            label: 'App Config',
            key: '/inspection/config',
            icon: <ControlOutlined />,
            onClick: () => router.push('/inspection/config')
          },
          {
            label: 'Notification Schedules',
            key: '/schedules',
            icon: <CalendarOutlined />,
            onClick: () => router.push('/schedules')
          },
          {
            label: 'External Accounts',
            key: '/external-accounts',
            icon: <ApiOutlined />,
            onClick: () => router.push('/external-accounts')
          }
        ]
      : []),
      {
        label: 'Clients',
        key: '/clients',
        icon: <ContactsOutlined />,
        onClick: () => router.push('/clients')
      },
    {
      label: 'Log Out',
      key: '/api/logout',
      icon: <LogoutOutlined />,
      onClick: () => logout().then(() => router.push('/login'))
    }
  ];

  return (
    <Sider
      style={{
        overflow: 'auto',
        height: '100vh',
        position: 'fixed',
        left: 0,
        top: 0,
        bottom: 0
      }}
    >
      <div css={xw``}>
        <img src='/img/logo-s.png' alt='QHI Logo' />
      </div>
      <Menu theme='dark' mode='inline' defaultSelectedKeys={[router.pathname]} items={menuItems} />
    </Sider>
  );
};

export default NavBar;
