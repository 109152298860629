import axios from 'axios';
import { UserRoles } from 'entities';
import { useQuery } from 'react-query';
import { UserTokenPayload } from 'types';

axios.defaults.withCredentials = true;

export const useUserProfile = () =>
  useQuery(
    ['userProfile'],
    () =>
      axios.get<void, { data: UserTokenPayload & { isAdmin: boolean } }>('/api/user/profile').then((resp) => resp.data),
    {
      retry: (count, { response }: any) => {
        const { status } = response;
        if (status == 401 || status == 403) {
          return false;
        }
        return count < 3;
      }
    }
  );

export const useCurrentUserRole = () => {
  const { data: userProfile } = useUserProfile();
  return {
    isAdmin: userProfile?.isAdmin,
    isSuperAdmin: userProfile?.role === UserRoles.SuperAdmin,
    role: userProfile?.role
  };
};
