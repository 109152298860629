import { useRouter } from "next/dist/client/router";
import { FC, useEffect, useState } from "react";
import xw from 'xwind';
import styles from './PageLoadingIndicator.module.css';


const PageLoadingIndicator: FC<any> = ({ children }) => {
  const { events } = useRouter();
  const [isLoading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    events.on('routeChangeStart', () => setLoading(true));
    events.on('routeChangeComplete', () => setLoading(false));
  }, [])

  if (!isLoading) {
    return children;
  }

  return (
    <div css={xw`w-full fixed top-0`}>
      <div className={styles.slider}>
        <div className={styles.line}></div>
        <div className={styles.topLine}></div>
        <div className={styles.bottomLine}></div>
      </div>
    </div>
  )
}

export default PageLoadingIndicator;